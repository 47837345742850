/* eslint-disable no-shadow */
import { Category } from './category.entity';
import { Contest } from './contest.entity';
import { EvalCategory } from './eval.category.entity';
import { Round } from './round.entity';

// eslint-disable-next-line no-shadow
export enum Events {
   _id = '_id',
   code = 'code',
   name = 'name',
   contest = 'contest',
   categories = 'categories',
   rounds = 'rounds',
   evalCategories = 'evalCategories',
   isActive = 'isActive',
   isClaimed = 'isClaimed',
   claimedDate = 'claimedDate',
   isPreliminary = 'isPreliminary',
   preliminaryDate = 'preliminaryDate',
   isFinal = 'isFinal',
   finalDate = 'finalDate',
   firstRound = 'firstRound',
   isSubmittedLogs = 'isSubmittedLogs',
   isSoapbox = 'isSoapbox',
   isOperators = 'isOperators',
   isSubmit = 'isSubmit',
   submitDeadline = 'submitDeadline',
   isDailyUploaded = 'isDailyUploaded',
   isOperatorList = 'isOperatorList',
   isOperatorListAlways = 'isOperatorListAlways',
   isChecklogShown = 'isChecklogShown',
   isPreliminaryCountBad = 'isPreliminaryCountBad',
   isOnlineLogger = 'isOnlineLogger',
   isAdminSubmit = 'isAdminSubmit',
   ofTimeMinutes = 'ofTimeMinutes',
   isResultFilter = 'isResultFilter',
   year = 'year',
   isDeleted = 'isDeleted',
   scoring = 'scoring',
   pointbase = 'pointbase',
   multipliers = 'multipliers',
   duping = 'duping',
   path = 'path',
   formats = 'formats',
   qualification = 'qualification',
   preserveCategories = 'preserveCategories',
   isRelativeResults = 'isRelativeResults',
   isCategoryCheck = 'isCategoryCheck',
   image = 'image',
   isCustomPrivacyPolicy = 'isCustomPrivacyPolicy',
   privacyPolicyName = 'privacyPolicyName',
   privacyPolicyLink = 'privacyPolicyLink',
   isSendEmail = 'isSendEmail',
   emailHtml = 'emailHtml',
   emailOptions = 'emailOptions',
   site = 'site',
   isSiteOnly = 'isSiteOnly',
   isSentNumberField = 'isSentNumberField',
   sentNumberFieldName = 'sentNumberFieldName',
   isSentStringField = 'isSentStringField',
   sentStringFieldName = 'sentStringFieldName',
   isStationSponsor = 'isStationSponsor',
   isNumberAndExchange = 'isNumberAndExchange',
   isDoubleExchange = 'isDoubleExchange',
   award = 'award',
   awardSite = 'awardSite',
   createdAt = 'createdAt',
   updatedAt = 'updatedAt',
   reverseMinQsos = 'reverseMinQsos',
   isReverseWithoutExchCheck = 'isReverseWithoutExchCheck',
   isSumOfTotalScoresPerRegion = 'isSumOfTotalScoresPerRegion',
   isSumOfTotalScoresPerCountry = 'isSumOfTotalScoresPerCountry',
   isSumOfTotalScoresPerCountryPerRound = 'isSumOfTotalScoresPerCountryPerRound',
   isOffAirContest = 'isOffAirContest',
   isReverseWithSameSentNumber = 'isReverseWithSameSentNumber',
   isReverseReceivedNumberAvailable = 'isReverseReceivedNumberAvailable',
   isHideEmptyCategories = 'isHideEmptyCategories',
   isSentErrorsCountAsMultipliers = 'isSentErrorsCountAsMultipliers',
   isReceivedNrErrorsCountAsMultipliers = 'isReceivedNrErrorsCountAsMultipliers',
   reverseLogNoMultUnderQsoNumber = 'reverseLogNoMultUnderQsoNumber',
   nilCallsignSearchMinimumSimilarity = 'nilCallsignSearchMinimumSimilarity',
   sentStringFieldAllowed = 'sentStringFieldAllowed',
   isSubmittedList = 'isSubmittedList',
   isMraszCompetitor = 'isMraszCompetitor',
   uploadedFileNames = 'uploadedFileNames',
   rules = 'rules',
}

export interface IResultFilter {
   isAvailable: boolean;
   dxccs: string;
   name: string;
}

export class Event {
   public [Events.code]!: string;

   public [Events.name]!: string;

   public [Events.contest]!: Contest;

   public [Events.categories]!: Category[];

   public [Events.rounds]!: Round[];

   public [Events.evalCategories]!: EvalCategory[];

   // modes.entity, bands.entity létrehozása, azokra referencia létrehozása
   public [Events.isActive]!: boolean;

   public [Events.isClaimed]?: boolean;

   public [Events.claimedDate]?: Date;

   public [Events.isPreliminary]?: boolean;

   public [Events.isOnlineLogger]?: boolean;

   public [Events.preliminaryDate]?: Date;

   public [Events.isFinal]?: boolean;

   public [Events.finalDate]?: Date;

   public [Events.firstRound]?: IFirstRound;

   public [Events.isSubmittedLogs]?: boolean;

   public [Events.isSoapbox]?: boolean;

   public [Events.isOperators]?: boolean;

   public [Events.isSubmit]?: boolean;

   public [Events.submitDeadline]?: Date;

   // claimed időpont, feltöltött logok időpont, submit időpont
   public [Events.isDailyUploaded]?: boolean;

   public [Events.isOperatorList]?: boolean;

   public [Events.isOperatorListAlways]?: boolean;

   public [Events.isChecklogShown]?: boolean;

   public [Events.isPreliminaryCountBad]?: boolean;

   public [Events.isAdminSubmit]?: boolean;

   public [Events.ofTimeMinutes]?: number;

   public [Events.isResultFilter]?: IResultFilter;

   public [Events.year]!: number;

   public [Events.isDeleted]?: boolean;

   public [Events.scoring]?: string;

   public [Events.pointbase]?: string;

   public [Events.multipliers]?: string[];

   public [Events.duping]!: string;

   public [Events.path]?: string;

   public [Events.formats]!: string[];

   public [Events.qualification]?: number;

   public [Events.preserveCategories]?: Event[];

   public [Events.isRelativeResults]?: boolean;

   public [Events.isCategoryCheck]?: boolean;

   public [Events.image]?: string;

   public [Events.isCustomPrivacyPolicy]!: boolean;

   public [Events.privacyPolicyName]?: string;

   public [Events.privacyPolicyLink]?: string;

   public [Events.isSendEmail]?: boolean;

   public [Events.emailHtml]?: string;

   public [Events.emailOptions]!: Record<string, unknown>;

   public [Events.site]: string;

   public [Events.isSiteOnly]: boolean;

   public [Events.isSentNumberField]: boolean;

   public [Events.sentNumberFieldName]: boolean;

   public [Events.isSentStringField]: boolean;

   public [Events.sentStringFieldName]: boolean;

   public [Events.isStationSponsor]: boolean;

   public [Events.isNumberAndExchange]: boolean;

   public [Events.isDoubleExchange]: boolean;

   public [Events.award]: Record<string, any>;

   public [Events.awardSite]: string;

   // tslint:disable: variable-name
   public [Events._id]!: string;

   public [Events.createdAt]!: Date;

   public [Events.updatedAt]!: Date;

   public [Events.reverseMinQsos]!: number;

   public [Events.isReverseWithoutExchCheck]!: boolean;

   public [Events.isSumOfTotalScoresPerRegion]!: boolean;

   public [Events.isSumOfTotalScoresPerCountry]!: boolean;

   public [Events.isSumOfTotalScoresPerCountryPerRound]!: boolean;

   public [Events.isOffAirContest]?: boolean;

   public [Events.isReverseWithSameSentNumber]?: boolean;

   public [Events.isReverseReceivedNumberAvailable]?: boolean;

   public [Events.isHideEmptyCategories]?: boolean;

   public [Events.isSentErrorsCountAsMultipliers]?: boolean;

   public [Events.isReceivedNrErrorsCountAsMultipliers]?: boolean;

   public [Events.reverseLogNoMultUnderQsoNumber]?: number;

   public [Events.nilCallsignSearchMinimumSimilarity]?: number;

   public [Events.sentStringFieldAllowed]?: string;

   public [Events.isSubmittedList]?: boolean;

   public [Events.isMraszCompetitor]?: boolean;

   public [Events.uploadedFileNames]: string[];

   public [Events.rules]?: string;
}

export interface GetSubmit {
   [Events._id]: string;

   [Events.image]: string;

   [Events.rounds]: Round[];

   [Events.evalCategories]: EvalCategory[];
}

export interface GetCategoryTypes {
   categoryTypes: Array<{
      type: { _id: string; code: string; name: string };
      categories: Array<{
         _id: string;
         code: string;
         name: string;
         isMultiOperator: boolean;
         isMraszMember: boolean | null;
         maxOptions: number;
         optionalCategories: Category[];
      }>;
   }>;
}

export interface GetSubmitOpt {
   [Events.submitDeadline]: Date;

   [Events.isCategoryCheck]?: boolean;

   [Events.isOperatorList]: boolean;

   [Events.isOnlineLogger]: boolean;

   [Events.isOperatorListAlways]: boolean;

   [Events.preserveCategories]: Event[];

   [Events.privacyPolicyName]: string;

   [Events.privacyPolicyLink]: string;

   [Events.isSentNumberField]: boolean;

   [Events.isSentStringField]: boolean;

   [Events.sentNumberFieldName]: string;

   [Events.sentStringFieldName]: string;

   [Events.isStationSponsor]: boolean;

   [Events.isMraszCompetitor]: boolean;
}

export interface IZoomData {
   formats: string[][];
   scoring: string[][];
   pointbase: string[][];
   multipliers: string[][];
   duping: string[][];
}

export interface IFirstRound {
   start: Date | string;
   _id: string;
}
