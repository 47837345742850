/* eslint-disable no-shadow */
export enum CategoryTypes {
   code = 'code',
   name = 'name',
   isActive = 'isActive',
   order = 'order',
   _id = '_id',
   createdAt = 'createdAt',
   updatedAt = 'updatedAt',
}

export class CategoryType {
   public [CategoryTypes.code]!: string;

   public [CategoryTypes.name]!: string;

   public [CategoryTypes.isActive]!: boolean;

   // tslint:disable-next-line: variable-name
   public [CategoryTypes._id]!: string;

   public [CategoryTypes.createdAt]!: Date;

   public [CategoryTypes.updatedAt]!: Date;

   public [CategoryTypes.order]?: number;
}
