import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService, TranslatePipe, isResultValid, translate } from '@szegedsw/http.service';
import { ButtonType, InitButton, ModalHandler, translateModal } from '@szegedsw/modal';
import { ElemList, getControl, getInvalid } from '@szegedsw/select';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { api, callsignRegExp } from '../config/config';
import { Category } from '../entities/category.entity';
import { CategoryType } from '../entities/category.type.entity';
import { Event, Events, GetCategoryTypes, GetSubmit, GetSubmitOpt } from '../entities/event.entity';

@Component({
   selector: 'app-logger',
   templateUrl: './logger.component.html',
   styleUrls: ['./logger.component.css'],
})
export class LoggerComponent implements OnInit {
   translate = translate;
   event: GetSubmit & GetSubmitOpt;
   categoryTypesInput: GetCategoryTypes['categoryTypes'];
   filterCategories: string[] = [];
   categoryTypes: CategoryType[] = [];
   events: Event[];

   elemList: ElemList = [];

   submitForm = this.fb.group({
      information: this.fb.control(false, Validators.required),
      callsign: this.fb.control(
         { value: '', disabled: false },
         {
            validators: [Validators.required, Validators.pattern(callsignRegExp)],
            asyncValidators: [
               (control) => {
                  return new Promise((resolve) => {
                     if (this.event.preserveCategories.length > 0) {
                        new HttpService(this.http, api('nest', 'submit/preserve-categories')).get<{ categories: any[] }>(
                           { eventId: this.event._id, callsign: control.value },
                           (res) => {
                              if (isResultValid(res)) {
                                 this.resetEvent.next();
                                 this.filterCategories = res.body.categories;
                              }
                              resolve(undefined);
                           },
                        );
                     }
                     resolve(undefined);
                  });
               },
            ],
            updateOn: 'blur',
         },
      ),
      email: this.fb.control('', [Validators.required, Validators.email]),
      operators: this.fb.control('', Validators.required),
      policy: this.fb.control(false, Validators.required),
      files: this.fb.array([]),
      sentNumberField: this.fb.control('', [
         (control) => (this.event && this.event[Events.isSentNumberField] && !control.value ? { required: true } : null),
      ]),
      sentStringField: this.fb.control('', [
         (control) => (this.event && this.event[Events.isSentStringField] && !control.value ? { required: true } : null),
      ]),
      stationSponsor: this.fb.control(''),
   });

   loadEvent: Subject<void> = new Subject<void>();
   loadObs = this.loadEvent.asObservable();
   disableEvent: Subject<boolean> = new Subject<boolean>();
   disableObs = this.disableEvent.asObservable();
   loadTypes = new Subject<void>();
   loadTypesObs = this.loadTypes.asObservable();
   resetEvent = new Subject<void>();
   resetObs = this.resetEvent.asObservable();

   errorModal = new ModalHandler();
   progressModal = new ModalHandler();
   expiredModal = new ModalHandler();
   modalOpenedFor = '';
   optionalCategories: Category[] = [];
   chosenCategoriesFromModal: string[] = [];

   submitService: HttpService;
   isAdminSubmit: boolean;
   isExpired: boolean;
   isNotAttached: boolean;
   isInformationInvalid: boolean;
   isPolicyInvalid: boolean;

   locale = TranslatePipe.getLocale();

   uploadId: string;

   isMraszCompetitor?: boolean = undefined;
   initCallsign = false;

   constructor(private http: HttpClient, private route: ActivatedRoute, private fb: FormBuilder, private router: Router) {
      this.isAdminSubmit = this.route.snapshot.url.some((u) => u.path === 'admin-submit');
      this.errorModal.title = 'Log cannot be processed!';
      this.errorModal.text = 'We could not process your log file. Please check that it is the correct format.';
      this.errorModal.closeButton = false;
      this.errorModal.keyboard = false;
      this.errorModal.ignoreBackdropClick = true;
      this.errorModal.classes = ['modal-dialog-centered', 'modal-lg'];

      this.progressModal.title = 'Log is being processed...';
      this.progressModal.text = 'Please wait for the redirection in background and check your uploaded log.';
      this.progressModal.closeButton = false;
      this.progressModal.keyboard = false;
      this.progressModal.ignoreBackdropClick = true;
      this.progressModal.classes = ['modal-dialog-centered', 'modal-lg'];

      this.expiredModal.title = 'Log submission expired!';
      this.expiredModal.text = 'Submitting log is not possible for this event anymore.';

      [this.errorModal.buttons, this.expiredModal.buttons, this.progressModal.buttons] = InitButton(
         { type: ButtonType.Ok, prefix: 'upload' },
         3,
      );
      translateModal(this.errorModal, translate);
      translateModal(this.expiredModal, translate);
      TranslatePipe.changed.subscribe(() => {
         this.errorModal.change.next(translateModal(this.errorModal, translate));
         this.expiredModal.change.next(translateModal(this.expiredModal, translate));
      });
   }

   async ngOnInit() {
      this.events = await AppComponent.getEvents();
      if (!this.isAdminSubmit) {
         this.events = this.events.filter((event) => event.isSubmit);
      }
      this.route.paramMap.subscribe((params) => {
         new HttpService(this.http, api('nest', 'submit/event-details')).get<
            { event: GetSubmit & GetSubmitOpt } & GetCategoryTypes
         >({ eventId: params.get('event') }, (res) => {
            this.initValues();
            if (isResultValid(res)) {
               this.event = res.body.event;
               this.categoryTypesInput = res.body.categoryTypes;
               this.categoryTypes = this.categoryTypesInput.map((categoryType) => categoryType.type) as CategoryType[];
               setTimeout(() => {
                  this.submitForm.get('event').setValue(this.event._id);
                  this.loadTypes.next();
                  this.disableEvent.next(true);
                  if (!this.events.map((event) => event._id).includes(this.event._id)) {
                     this.expiredModal.event.next();
                  }
               }, 1000);
            }
         });
      });
   }

   isFormInvalid(): boolean {
      if (!this.events || !this.event || !this.events.map((contest) => contest._id).includes(this.event._id)) {
         return true;
      } else if (this.submitForm && (this.submitForm.get('callsign').invalid || this.submitForm.get('email').invalid)) {
         return true;
      } else if (getInvalid(this.elemList)) {
         return true;
      } else if (
         this.submitForm &&
         this.event.isOperatorList &&
         (this.isMultiChosen() || this.event.isOperatorListAlways) &&
         this.submitForm.get('operators').invalid
      ) {
         return true;
      } else if (
         this.submitForm &&
         (this.submitForm.get('sentNumberField').invalid || this.submitForm.get('sentStringField').invalid)
      ) {
         return true;
      } else if (this.submitForm && !this.submitForm.get('policy').value) {
         return true;
      }
      return false;
   }

   onVerify() {
      if (!this.events || !this.event || !this.events.map((contest) => contest._id).includes(this.event._id)) {
         this.isExpired = true;
         return;
      }
      if (this.submitForm && !this.submitForm.get('information').value) {
         this.isInformationInvalid = true;
         return;
      }
      if (this.submitForm && (this.submitForm.get('callsign').invalid || this.submitForm.get('email').invalid)) {
         this.submitForm.get('callsign').markAsTouched();
         this.submitForm.get('email').markAsTouched();
         return;
      }
      if (getInvalid(this.elemList)) {
         this.elemList.forEach((e) => e.control.markAsTouched());
         return;
      }
      if (
         this.submitForm &&
         this.event.isOperatorList &&
         (this.isMultiChosen() || this.event.isOperatorListAlways) &&
         this.submitForm.get('operators').invalid
      ) {
         this.submitForm.get('operators').markAsTouched();
         return;
      }
      if (this.submitForm && this.event[Events.isSentNumberField] && this.submitForm.get('sentNumberField').invalid) {
         this.submitForm.get('sentNumberField').markAsTouched();
         return;
      }
      if (this.submitForm && this.event[Events.isSentStringField] && this.submitForm.get('sentStringField').invalid) {
         this.submitForm.get('sentStringField').markAsTouched();
         return;
      } else {
         this.isNotAttached = false;
      }
      if (this.submitForm && !this.submitForm.get('policy').value) {
         this.isPolicyInvalid = true;
         return;
      }
   }

   onCancel() {
      this.initValues();
   }

   initValues() {
      this.isMraszCompetitor = undefined;
      this.initCallsign = false;
      this.chosenCategoriesFromModal = [];
      this.submitForm.get('callsign').reset();
      this.submitForm.get('email').reset();
      this.submitForm.get('operators').reset();
      this.submitForm.get('policy').setValue(false);
      this.resetEvent.next();
      this.filterCategories = [];
      this.submitForm.get('sentNumberField').reset();
      this.submitForm.get('sentStringField').reset();
      this.submitForm.get('stationSponsor').reset();
      this.submitForm.get('information').setValue(false);
   }

   isMultiChosen() {
      return this.elemList.some((e) =>
         this.categoryTypesInput.some((c) => {
            return c.categories.some((a) => a._id === e.control.value && a.isMultiOperator);
         }),
      );
   }

   onChosenCategoriesFromModalChange(event: string[]) {
      this.chosenCategoriesFromModal = event;
   }

   onSubmit() {
      const operators = this.event.isOperatorList ? this.submitForm.get('operators').value : undefined;
      new HttpService(this.http, api('nest', 'submit/start-online-logger')).post<any>(
         {
            uploadId: this.uploadId,
            callsign: this.submitForm.get('callsign').value,
            isCompetitor: this.event.isMraszCompetitor ? this.isMraszCompetitor : undefined,
            email: this.submitForm.get('email').value,
            operators: operators ? operators : undefined,
            eventId: getControl(this.elemList, 'event').value,
            categories: this.elemList
               .reduce<string[]>((previous, current) => {
                  if (current.name !== 'event' && current.control.value !== -1) {
                     previous.push(current.control.value);
                  }
                  return previous;
               }, [])
               .concat(this.chosenCategoriesFromModal),
            sentNumberField: this.event[Events.isSentNumberField] ? this.submitForm.get('sentNumberField').value : undefined,
            sentStringField: this.event[Events.isSentStringField] ? this.submitForm.get('sentStringField').value : undefined,
            isAdminSubmit: this.isAdminSubmit ? true : undefined,
            stationSponsor: this.submitForm.get('stationSponsor').value ? this.submitForm.get('stationSponsor').value : undefined,
            language: TranslatePipe.current(),
         },
         async (postRes) => {
            if (isResultValid(postRes)) {
               localStorage.setItem('evalCategories', JSON.stringify(postRes.body.evalCategories));
               localStorage.removeItem('qsoFormSettings');
               localStorage.removeItem('qsoFormValues');
               this.router.navigateByUrl('/online-logger/' + getControl(this.elemList, 'event').value + '/' + postRes.body._id);
            }
         },
      );
   }

   onDownloadManula() {
      if (this.locale === 'hu-HU') {
         window.open('assets/ONLINE_LOGger_hun.pdf');
      } else {
         window.open('assets/ONLINE_LOGger_eng.pdf');
      }
   }

   isMraszCompetitorEvent(event: boolean) {
      this.initCallsign = true;
      this.isMraszCompetitor = event;
   }
}
