/* eslint-disable max-classes-per-file */

export enum EvalCategories {
   code = 'code',
   name = 'name',
   categories = 'categories',
   rounds = 'rounds',
   isActive = 'isActive',
   isDetailed = 'isDetailed',
   isChecklog = 'isCheckLog',
   isSWL = 'isSWL',
   isQualified = 'isQualified',
   isConditional = 'isConditional',
   isSumScore = 'isSumScore',
   is6Hours = 'is6Hours',
   isTenMinuteRule = 'isTenMinuteRule',
   isPerMode = 'isPerMode',
   conditionFirst = 'conditionFirst',
   bands = 'bands',
   maxBands = 'maxBands',
   modes = 'modes',
   isSentExchange = 'isSentExchange',
   order = 'order',
   _id = '_id',
   createdAt = 'createdAt',
   updatedAt = 'updatedAt',
}

export class EvalCategory {
   public [EvalCategories.code]!: string;

   public [EvalCategories.name]!: string;

   public [EvalCategories.categories]!: any[];

   public [EvalCategories.rounds]!: any[];

   public [EvalCategories.isActive]!: boolean;

   public [EvalCategories.isDetailed]?: boolean;

   public [EvalCategories.isChecklog]?: boolean;

   public [EvalCategories.isSWL]?: boolean;

   public [EvalCategories.isQualified]?: boolean;

   public [EvalCategories.isConditional]?: boolean;

   public [EvalCategories.isSumScore]?: boolean;

   public [EvalCategories.is6Hours]?: boolean;

   public [EvalCategories.isTenMinuteRule]?: boolean;

   public [EvalCategories.isPerMode]?: boolean;

   public [EvalCategories.conditionFirst]?: number;

   public [EvalCategories.bands]?: string[];

   public [EvalCategories.maxBands]?: number;

   public [EvalCategories.modes]?: string[];

   public [EvalCategories.isSentExchange]?: boolean;

   public [EvalCategories.order]?: number;

   public [EvalCategories._id]!: string;

   public [EvalCategories.createdAt]!: Date;

   public [EvalCategories.updatedAt]!: Date;

   chosen: any;

   isSelfPlaced: boolean;
}

export class EventEvals {
   // tslint:disable-next-line: variable-name
   public _id!: string;

   public name!: string;
}
