/* eslint-disable prefer-rest-params */
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService, isResultValid, translate, TranslatePipe } from '@szegedsw/http.service';
import { IconClass } from '@szegedsw/icon';
import { Body, ButtonType, ControlType, InitButton, ModalHandler, translateModal } from '@szegedsw/modal';
import { addIcon, Codes, colspan, convertToBool, Headers, IIconEvent, rowspan, translateHeaders } from '@szegedsw/table';
import _ from 'lodash';
import { TimepickerComponent } from 'ngx-bootstrap/timepicker';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { api, callsignRegExp } from '../config/config';
import { Band, Mode } from '../eval/qso-list/qso-list.enums';
import { Icons } from '../handlers/icon.handler';

const CustomBreakpoints = {
   mobileM: '(max-width: 800px)',
} as const;
@Component({
   selector: 'app-qso-typer',
   templateUrl: './qso-typer.component.html',
   styleUrls: ['./qso-typer.component.css'],
})
export class QsoTyperComponent implements OnInit {
   today = 0;

   date = new Date();

   qsos: Qso[] = [];

   offlineQsos: Qso[] = [];

   bandRaw = Object.values(Band);
   band = [];
   modeRaw = Object.values(Mode);
   mode = [];
   setMode = '';

   periodMins = 10;
   dupePeriod = true;
   dupeBand = true;
   dupeMode = true;
   dupe = true;
   isDupe = false;
   iconHandler: Icons;

   loadBandEvent: Subject<string> = new Subject<string>();
   loadModeEvent: Subject<string> = new Subject<string>();
   resetEvent: Subject<string> = new Subject<string>();

   qsoSettingsForm = new FormGroup({
      autoTime: new FormControl(true),
      autoNum: new FormControl(),
      copyExchange: new FormControl(true),
      copyNumber: new FormControl(),
      isFreq: new FormControl(),
      isXQso: new FormControl(),
   });

   autoTime = false;
   autoNum = true;
   copyExchange = true;
   copyNumber = false;
   isFreq = false;
   isXQso = false;

   isOnlineChosenExchange = false;

   isOffline = false;

   qsoForm = new FormGroup({
      date: new FormControl('', Validators.required),
      time: new FormControl('', Validators.required),
      freq: new FormControl(''),
      call: new FormControl('', [Validators.required, Validators.pattern(callsignRegExp)]),
      sRst: new FormControl('', Validators.required),
      sNum: new FormControl(),
      sExch: new FormControl(),
      sWwl: new FormControl(),
      rRst: new FormControl('', Validators.required),
      rNum: new FormControl(),
      rExch: new FormControl(),
      rWwl: new FormControl(),
      band: new FormControl(),
      mode: new FormControl(),
   });

   editQso = false;
   renderQso = true;

   qsoEditForm = new FormGroup({
      _id: new FormControl(),
      nr: new FormControl(),
      date: new FormControl('', Validators.required),
      time: new FormControl('', Validators.required),
      freq: new FormControl(''),
      call: new FormControl('', Validators.required),
      sRst: new FormControl('', Validators.required),
      sNum: new FormControl(),
      sExch: new FormControl(),
      sWwl: new FormControl(),
      rRst: new FormControl('', Validators.required),
      rNum: new FormControl(),
      rExch: new FormControl(),
      rWwl: new FormControl(),
      band: new FormControl(),
      mode: new FormControl(),
   });

   headers: Headers;
   codes: Codes;

   renderTable = false;
   renderTableContent = false;

   origBand = undefined;
   origMode = undefined;

   eventId = '';
   logId = '';

   logService: HttpService;
   detailsService: HttpService;
   submitService: HttpService;
   eventService: HttpService;

   deviceType = '';

   justReloaded = false;

   evalCategories: string[] = [];
   contestName = '';
   isOffAirContest = false;

   fieldsToShow = { sNum: false, sExch: false, sWwl: false, rNum: false, rExch: false, rWwl: false };

   finishModal: ModalHandler = new ModalHandler();
   deleteModal: ModalHandler = new ModalHandler();
   xModal: ModalHandler = new ModalHandler();

   ownCallsign = '';
   sentNumber: number = undefined;
   sentString: string = undefined;

   @ViewChild('first', { static: false }) firstElement: ElementRef;
   @ViewChild('call', { static: false }) callElement: ElementRef;
   @ViewChild('num', { static: false }) numElement: ElementRef;
   @ViewChild('exch', { static: false }) exchElement: ElementRef;
   @ViewChild('wwl', { static: false }) wwlElement: ElementRef;
   @ViewChild('time', { static: false }) timeElement: TimepickerComponent;

   constructor(
      private route: ActivatedRoute,
      private http: HttpClient,
      private breakpointObserver: BreakpointObserver,
      private router: Router,
   ) {
      this.logService = new HttpService(this.http, api('nest', 'qso/online-logger'));
      this.submitService = new HttpService(this.http, api('nest', 'submit/claim-online-logger'));
      this.detailsService = new HttpService(this.http, api('nest', 'submit/online-logger-details'));
      this.eventService = new HttpService(this.http, api('nest', 'events/online-logger'));
      this.breakpointObserver
         .observe([CustomBreakpoints.mobileM, Breakpoints.Web])
         .pipe(
            map((state: BreakpointState): any => {
               if (state.breakpoints[CustomBreakpoints.mobileM]) {
                  return 'mobile';
               }
               return 'web'; // default
            }),
         )
         .subscribe((r) => {
            this.deviceType = r;
         });
      this.finishModal.title = 'Are you sure you want to finish and submit?';
      this.finishModal.text = 'You cannot edit this log after you submit it.';
      this.finishModal.ignoreBackdropClick = true;
      [this.finishModal.buttons] = InitButton({ type: ButtonType.CancelYes, prefix: 'finish' }, 1);

      this.deleteModal.title = 'Are you sure you want to delete this QSO?';
      this.deleteModal.text = 'You can not restore it!';
      this.deleteModal.ignoreBackdropClick = true;
      [this.deleteModal.buttons] = InitButton({ type: ButtonType.CancelYes, prefix: 'delete' }, 1);

      this.xModal.title = 'Are you sure to mark this as X-QSO? It means this QSO will not be scored.';
      this.xModal.ignoreBackdropClick = true;
      [this.xModal.buttons] = InitButton({ type: ButtonType.CancelYes, prefix: 'delete' }, 1);

      const translateComponent = () => {
         translateHeaders(this.headers, translate);
         translateModal(this.finishModal, translate);
         translateModal(this.deleteModal, translate);
         translateModal(this.xModal, translate);
      };
      TranslatePipe.changed.subscribe(translateComponent);
   }

   ngOnInit() {
      this.evalCategories = JSON.parse(localStorage.getItem('evalCategories'));
      this.route.paramMap.subscribe((p) => {
         this.eventId = p.get('event');
         this.logId = p.get('id');
         this.getEventDetails();
      });
      this.renderQso = false;
      this.http.get('/nest/qso/online-logger/' + this.eventId + '/' + this.logId).subscribe((res: any) => {
         res.forEach((q) => {
            const qso: Qso = q;
            qso.call = qso.callsign;
            qso.nr = this.qsos[0] ? this.qsos[0].nr + 1 : 1;
            const offset = new Date().getTimezoneOffset() * 60 * 1000;
            qso.date = this.toDate(new Date(qso.dateTime));
            qso.time = this.toTime(new Date(new Date().setTime(new Date(qso.dateTime).getTime() + offset)));
            const h = parseInt(qso.time.split(':')[0]);
            const m = parseInt(qso.time.split(':')[1]);
            if (this.periodMins < 60) {
               qso.period = h * 100 + Math.floor(m / this.periodMins) * this.periodMins;
            } else if (this.periodMins === 60) {
               qso.period = h * 100;
            }
            qso.xQso = qso.status === 'X-QSO';
            this.qsos.unshift(qso);
            addIcon(this.qsos, 'edit', new IconClass('fas:faEdit', 'edit', [], translate('Modify QSO')));
            addIcon(this.qsos, 'delete', new IconClass('fas:faTimes', 'delete', [], translate('Delete QSO')));
            convertToBool(this.qsos, ['dupe', 'xQso']);
            this.renderTableContent = true;
         });
         if (res.length === 0) {
            this.renderTableContent = true;
            if (this.isOffAirContest) {
               this.qsoForm.get('sNum').setValue(1);
            }
         } else {
            if (this.isOffAirContest) {
               this.qsoForm.get('sNum').setValue(this.qsos[0].sNum + 1);
            }
         }
         this.renderQso = true;
      });
      this.qsoForm.get('sRst').setValue(59);
      setInterval(() => {
         this.today = Date.now();
         this.date = new Date(this.today);
         if (this.autoTime) {
            this.qsoForm.get('date').setValue(this.date);
            this.qsoForm
               .get('time')
               .setValue(new Date(new Date(this.date).getTime() + new Date().getTimezoneOffset() * 60 * 1000));
         }
         this.onCallsignChange();
      }, 1000);
      setInterval(() => {
         if (this.isOffline && this.offlineQsos.length > 0) {
            this.sendOffline();
         }
      }, 10000);
      if (!this.autoTime) {
         this.qsoForm.get('time').setValue(new Date(new Date(0).getTime() + new Date().getTimezoneOffset() * 60 * 1000));
      }
   }

   getEventDetails() {
      this.detailsService.get({ logId: this.logId }, (res: any) => {
         if (isResultValid(res)) {
            this.ownCallsign = res.body.callsign;
            this.sentNumber = res.body.sentNumberField;
            this.sentString = res.body.sentStringField;
            if (this.sentNumber && !this.isOffAirContest) {
               this.copyNumber = true;
               this.qsoForm.get('sNum').reset(this.sentNumber);
               this.qsoForm.get('sNum').disable();
               this.qsoEditForm.get('sNum').reset(this.sentNumber);
               this.qsoEditForm.get('sNum').disable();
            }
            if (this.sentString) {
               this.copyExchange = true;
               this.qsoForm.get('sExch').reset(this.sentString);
               this.qsoForm.get('sExch').disable();
               this.qsoEditForm.get('sExch').reset(this.sentString);
               this.qsoEditForm.get('sExch').disable();
            }
         }
      });
      this.eventService.post<any>({ id: this.eventId, evalCategories: this.evalCategories }, (res) => {
         if (isResultValid(res)) {
            this.isOnlineChosenExchange = res.body.isOnlineChosenExchange;
            this.dupeBand = res.body.duping === 'BAND' || res.body.duping === 'BAND-MODE';
            this.dupeMode = res.body.duping === 'BAND-MODE' || res.body.duping === 'PERIOD-MODE';
            this.dupePeriod = res.body.duping === 'PERIOD' || res.body.duping === 'PERIOD-MODE';
            this.dupe = this.dupeBand || this.dupeMode;
            this.contestName = res.body.name;
            this.isOffAirContest = res.body.isOffAirContest;
            if (this.isOffAirContest) {
               this.autoNum = true;
               this.autoTime = true;
            }
            res.body.bands.forEach((r) => {
               this.band.push({ _id: r, name: r });
            });
            res.body.modes.forEach((r) => {
               this.mode.push({ _id: r, name: r });
            });
            setTimeout(() => {
               this.loadBandEvent.next();
               this.loadModeEvent.next();
               if (localStorage.getItem('qsoFormValues') || localStorage.getItem('qsoEditFormValues')) {
                  this.editQso = localStorage.getItem('editQso') === 'true';
                  if (this.editQso && localStorage.getItem('qsoEditFormValues')) {
                     this.justReloaded = true;
                     this.edit({
                        row: JSON.parse(localStorage.getItem('qsoEditFormValues')),
                        icon: '',
                        id: '',
                     });
                     this.justReloaded = false;
                  }
                  setTimeout(() => {
                     const formValues = JSON.parse(localStorage.getItem('qsoFormValues'));
                     const formEditValues = JSON.parse(localStorage.getItem('qsoEditFormValues'));
                     if (!this.editQso && formValues._id) {
                        delete formValues['_id'];
                     }
                     if (!this.editQso && formValues.nr) {
                        delete formValues.nr;
                     }
                     this.qsoForm.setValue(formValues);
                     this.qsoEditForm.setValue(formEditValues);
                     this.setRST();
                  }, 100);
               }
            }, 100);
            const fields: string[] = res.body.onlineLoggerFields;
            const row = ['RST'];
            const codeArray = ['nr', 'date', 'time', 'band'];
            const headerArray = [rowspan('#', 2), rowspan('Date', 2), rowspan('Time', 2), rowspan('Band', 2)];
            if (this.isFreq) {
               codeArray.push('freq');
               headerArray.push(rowspan('Freq.', 2));
            }
            codeArray.push('mode', 'call', 'sRst');
            headerArray.push(
               rowspan('Mode', 2),
               rowspan('Callsign', 2),
               colspan('Sent', fields.length / 2 + 1),
               colspan('Rcvd', fields.length / 2 + 1),
            );
            if (fields.includes('sNum')) {
               row.push('Num');
               codeArray.push('sNum');
            }
            if (fields.includes('sExch')) {
               row.push('Exch');
               codeArray.push('sExch');
            }
            if (fields.includes('sWwl')) {
               row.push('WWL');
               codeArray.push('sWwl');
            }
            row.push('RST');
            codeArray.push('rRst');
            if (fields.includes('rNum')) {
               row.push('Num');
               codeArray.push('rNum');
            }
            if (fields.includes('rExch')) {
               row.push('Exch');
               codeArray.push('rExch');
            }
            if (fields.includes('rWwl')) {
               row.push('WWL');
               codeArray.push('rWwl');
            }
            if (this.dupePeriod) {
               codeArray.push('period');
               headerArray.push(rowspan('Period', 2));
            }
            if (this.isXQso) {
               headerArray.push(rowspan('X-QSO', 2));
               codeArray.push('xQso');
            }
            headerArray.push(rowspan('Edit', 2), rowspan('Delete', 2));
            codeArray.push('edit', 'delete');
            this.headers = new Headers(headerArray).addRow(row);
            translateHeaders(this.headers, translate);
            this.codes = new Codes(codeArray);
            fields.forEach((f) => {
               this.fieldsToShow[f] = true;
            });
            this.renderTable = true;
         } else {
            this.bandRaw.forEach((r) => {
               this.band.push({ _id: r, name: r });
            });
            this.modeRaw.forEach((r) => {
               this.mode.push({ _id: r, name: r });
            });
            setTimeout(() => {
               this.loadBandEvent.next();
               this.loadModeEvent.next();
               if (localStorage.getItem('qsoFormValues') || localStorage.getItem('qsoEditFormValues')) {
                  this.editQso = localStorage.getItem('editQso') === 'true';
                  if (this.editQso && localStorage.getItem('qsoEditFormValues')) {
                     this.justReloaded = true;
                     this.edit({
                        row: JSON.parse(localStorage.getItem('qsoEditFormValues')),
                        icon: '',
                        id: '',
                     });
                     this.justReloaded = false;
                  }
                  setTimeout(() => {
                     this.qsoForm.setValue(JSON.parse(localStorage.getItem('qsoFormValues')));
                     this.qsoEditForm.setValue(JSON.parse(localStorage.getItem('qsoEditFormValues')));
                     this.setRST();
                  }, 100);
               }
            }, 100);

            this.headers = new Headers([
               rowspan('#', 2),
               rowspan('Date', 2),
               rowspan('Time', 2),
               rowspan('Band', 2),
               rowspan('Freq.', 2),
               rowspan('Mode', 2),
               rowspan('Callsign', 2),
               colspan('Sent', 4),
               colspan('Rcvd', 4),
               rowspan('Period', 2),
               rowspan('X-QSO', 2),
               rowspan('Edit', 2),
               rowspan('Delete', 2),
            ]).addRow(['RST', 'Num', 'Exch', 'WWL', 'RST', 'Num', 'Exch', 'WWL']);
            this.codes = new Codes([
               'nr',
               'date',
               'time',
               'band',
               'freq',
               'mode',
               'call',
               'sRst',
               'sNum',
               'sExch',
               'sWwl',
               'rRst',
               'rNum',
               'rExch',
               'rWwl',
               'period',
               'xQso',
               'edit',
               'delete',
            ]);
            translateHeaders(this.headers, translate);
            this.renderTable = true;
         }
      });
   }

   convertDate(): string {
      return formatDate(this.date, 'yyyy-MM-dd hh:mm:ss', 'hu');
   }

   toDate(date: Date): string {
      return formatDate(date, 'yyyy-MM-dd', 'hu');
   }

   toTime(date: Date): string {
      return formatDate(date, 'HH:mm', 'hu');
   }

   loadEvents() {
      this.loadBandEvent.next();
      this.loadModeEvent.next();
   }

   changeSettings(event) {
      this.autoTime = event.autoTime;
      this.autoNum = event.autoNum;
      this.copyExchange = event.copyExchange;
      this.copyNumber = event.copyNumber;
      this.isFreq = event.isFreq;
      this.isXQso = event.isXQso;

      if (this.autoTime) {
         this.qsoForm.get('date').setValue(this.date);
         this.qsoForm.get('time').setValue(new Date(new Date(this.date).getTime() + new Date().getTimezoneOffset() * 60 * 1000));
      } else {
         this.qsoForm.get('time').setValue(new Date(new Date(0).getTime() + new Date().getTimezoneOffset() * 60 * 1000));
      }
      if (this.copyExchange) {
         this.qsoForm.get('sExch').disable();
      } else {
         this.qsoForm.get('sExch').enable();
      }
   }

   onIconClick(event: IIconEvent<Qso>) {
      if (!this.isOffline) {
         if (event.id === 'delete') {
            this.deleteModal.body = [];
            this.deleteModal.body.push({ id: 'id', type: ControlType.text, value: event.row.nr, hidden: true });
            this.deleteModal.change.next(translateModal(this.deleteModal, translate));
            this.deleteModal.event.next();
         } else if (event.id === 'edit') {
            if (this.editQso) {
               this.editQso = false;
               this.renderQso = false;
               setTimeout(() => {
                  this.renderQso = true;
                  setTimeout(() => {
                     this.loadBandEvent.next();
                     this.loadModeEvent.next();
                     setTimeout(() => {
                        this.qsoForm.get('band').setValue(localStorage.getItem('origBand'));
                        this.qsoForm.get('mode').setValue(localStorage.getItem('origMode'));
                        setTimeout(() => {
                           this.renderTable = true;
                           this.edit(event);
                        }, 10);
                     }, 100);
                  }, 100);
               }, 100);
            } else {
               this.edit(event);
            }
         }
      }
   }

   onDelete(event: Body) {
      if (event.getKey() === 'delete-yes') {
         const i = this.qsos.findIndex((q) => q.nr === event.getField('id'));
         this.http.delete<any>('/nest/qso/online-logger/' + this.logId + '/' + this.qsos[i]._id).subscribe((res) => {
            if (res.acknowledged && res.deletedCount > 0) {
               this.qsos.splice(i, 1);
            }
         });
      }
   }

   edit(event: IIconEvent<Qso>) {
      const h = parseInt(event.row.time.split(':')[0]);
      const m = parseInt(event.row.time.split(':')[1]);
      const time = new Date(new Date(new Date().setHours(h)).setMinutes(m));
      this.origBand = this.qsoForm.get('band').value;
      this.origMode = this.qsoForm.get('mode').value;
      if (!this.editQso) {
         localStorage.setItem('origBand', this.qsoForm.get('band').value);
         localStorage.setItem('origMode', this.qsoForm.get('mode').value);
         if (this.copyExchange) {
            localStorage.setItem('origExch', this.qsoForm.get('sExch').value);
         }
         if (this.copyNumber) {
            localStorage.setItem('origNum', this.qsoForm.get('sNum').value);
         }
      }

      this.editQso = true;
      localStorage.setItem('editQso', this.editQso ? 'true' : 'false');
      this.renderQso = false;
      setTimeout(() => {
         this.renderQso = true;
         setTimeout(() => {
            this.loadBandEvent.next();
            this.loadModeEvent.next();
         }, 100);
         setTimeout(() => {
            this.resetEvent.next();
            this.qsoEditForm.get('_id').setValue(event.row._id);
            this.qsoEditForm.get('nr').setValue(event.row.nr);
            this.qsoEditForm.get('date').setValue(event.row.date);
            this.qsoEditForm.get('time').setValue(time);
            if (event.row.freq && event.row.freq !== null) {
               this.qsoEditForm.get('freq').setValue(event.row.freq);
            } else {
               this.qsoEditForm.get('freq').setValue(null);
            }
            this.qsoEditForm.get('band').setValue(event.row.band);
            this.qsoEditForm.get('mode').setValue(event.row.mode);
            this.qsoEditForm.get('call').setValue(event.row.call);
            this.qsoEditForm.get('sRst').setValue(event.row.sRst);
            this.qsoEditForm.get('sNum').setValue(event.row.sNum);
            this.qsoEditForm.get('sExch').setValue(event.row.sExch);
            if (event.row.sWwl) {
               this.qsoEditForm.get('sWwl').setValue(event.row.sWwl);
            }
            this.qsoEditForm.get('rRst').setValue(event.row.rRst);
            this.qsoEditForm.get('rNum').setValue(event.row.rNum);
            this.qsoEditForm.get('rExch').setValue(event.row.rExch);
            if (event.row.rWwl && event.row.rWwl != null) {
               this.qsoEditForm.get('rWwl').setValue(event.row.rWwl);
            }
            this.setRST();
            window.scroll({
               top: 0,
               left: 0,
               behavior: 'smooth',
            });
            if (!this.justReloaded) {
               this.onChangeForm();
            }
         }, 200);
      }, 100);
   }

   onXConfirm(event: any, x?: string) {
      if (x) {
         event.row.xQso = x === 'true';
      }
      if (event.row.xQso) {
         this.xModal.body = [];
         this.xModal.body.push({ id: 'id', type: ControlType.text, value: event.row.nr, hidden: true });
         this.xModal.body.push({ id: 'row', type: ControlType.text, value: JSON.stringify(event.row), hidden: true });
         this.xModal.change.next(this.xModal);
         this.xModal.event.next();
      } else {
         const i = this.qsos.findIndex((q) => q.nr === event.row.nr);
         if (i > -1) {
            this.logService.patch<Qso>(
               '',
               { ...this.qsos[i], qsoId: this.qsos[i]._id, xQso: event.row.xQso, eventId: this.eventId, logId: this.logId },
               (res) => {
                  if (isResultValid(res)) {
                     this.qsos[i].xQso = res.body.status === 'X-QSO';
                     this.qsos[i].dupe = false;
                     this.reDupes(event.row);
                  }
               },
            );
         }
      }
   }

   setRST() {
      const form = this.editQso ? this.qsoEditForm : this.qsoForm;
      if (form.get('mode') && form.get('mode') !== null) {
         if (form.get('mode').value === 'CW' || form.get('mode').value === 'RY' || form.get('mode').value === 'DG') {
            form.get('sRst').setValue('599');
            form.get('rRst').setValue('599');
            this.setMode = 'cw';
         } else {
            form.get('sRst').setValue('59');
            form.get('rRst').setValue('59');
            this.setMode = 'ssb';
         }
      }
      if (!this.editQso) {
         localStorage.setItem('origMode', this.qsoForm.get('mode').value);
      }
   }

   onX(event: Body) {
      const i = this.qsos.findIndex((q) => q.nr === event.getField('id'));
      const row = JSON.parse(event.getField('row'));
      if (event.getKey() === 'delete-yes') {
         if (i > -1) {
            this.logService.patch<Qso>(
               '',
               { ...this.qsos[i], qsoId: this.qsos[i]._id, xQso: true, eventId: this.eventId, logId: this.logId },
               (res) => {
                  if (isResultValid(res)) {
                     this.qsos[i].xQso = res.body.status === 'X-QSO';
                     this.qsos[i].dupe = false;
                     this.reDupes(row);
                  }
               },
            );
         }
      } else {
         this.qsos[i].xQso = false;
      }
   }

   onFinish(event?: Body) {
      if (!event) {
         this.finishModal.change.next(translateModal(this.finishModal, translate));
         this.finishModal.event.next();
      } else {
         if (event.getKey() === 'finish-yes') {
            this.submitService.post<{ _id: string }>({ eventId: this.eventId, logId: this.logId }, (res) => {
               if (isResultValid(res)) {
                  this.router.navigateByUrl('/review-qsos/' + res.body._id);
               }
            });
         }
      }
   }

   reDupes(qso: Qso) {
      // this.eventYears = _.keys(_.groupBy(this.events, 'year'))
      //    .map((k) => Number(k))
      //    .filter((k) => k !== this.year)
      //    .reverse()
      //    .map((y) => [y, this.events.filter((e) => e[Events.year] === y && e[Events.isFinal])]);
      const dupes = _.keys(_.groupBy(this.qsos, 'period'))
         .map((k) => Number(k))
         .filter((k) => k === qso.period)
         .reverse()
         .map((y) => [y, this.qsos.filter((e) => e['period'] === y && e['callsign'] === qso.callsign && !e['xQso'])]);
      const results: Qso[] = dupes[0][1] as Array<Qso>;
      results.sort((a, b) => a.nr - b.nr);
      if (results.length === 1) {
         this.changeDupe(results[0], false);
      } else {
         let i = 0;
         results.forEach((r) => {
            if (i === 0) {
               this.changeDupe(r, false);
            } else {
               this.changeDupe(r, true);
            }
            i++;
         });
      }
   }

   changeDupe(qso: Qso, dupe: boolean) {
      if (qso.dupe !== dupe) {
         this.logService.patch('', { ...qso, logId: this.logId, dupe, qsoId: qso._id }, (res) => {
            if (isResultValid(res)) {
               const index = this.qsos.findIndex((q) => q.nr === qso.nr);
               this.qsos[index].dupe = dupe;
            }
         });
      }
   }

   resetQsoForm() {
      if (this.autoNum || this.isOffAirContest) {
         this.qsoForm.get('sNum').setValue(this.qsoForm.get('sNum').value + 1);
      } else if (!this.copyNumber && !this.isOffAirContest) {
         this.qsoForm.get('sNum').reset();
      }
      if (!this.copyExchange) {
         this.qsoForm.get('sExch').reset();
      }
      this.qsoForm.get('rNum').reset();
      this.qsoForm.get('rExch').reset();
      this.qsoForm.get('rWwl').reset();
      this.qsoForm.get('call').reset();
      setTimeout(() => {
         if (this.autoTime) {
            this.callElement.nativeElement.focus();
         } else {
            (document.getElementsByClassName('bs-timepicker-field')[1] as any).value = null;
            (document.getElementsByClassName('bs-timepicker-field')[1] as any).focus();
         }
         this.renderTable = true;
         this.onChangeForm();
      }, 10);
   }

   onChangeForm() {
      const form = this.editQso ? this.qsoEditForm : this.qsoForm;
      localStorage.setItem('editQso', this.editQso ? 'true' : 'false');
      const data = form.getRawValue();
      const offset = new Date().getTimezoneOffset() * 60000;
      data.date = new Date(new Date().setTime(new Date(data.date).getTime() - offset));
      localStorage.setItem('qsoFormValues', JSON.stringify(this.qsoForm.getRawValue()));
      localStorage.setItem('qsoEditFormValues', JSON.stringify(this.qsoEditForm.getRawValue()));
      if (this.sentNumber && !this.isOffAirContest) {
         this.copyNumber = true;
         this.qsoForm.get('sNum').reset(this.sentNumber);
         this.qsoForm.get('sNum').disable();
         this.qsoEditForm.get('sNum').reset(this.sentNumber);
         this.qsoEditForm.get('sNum').disable();
      }
      if (this.sentString) {
         this.copyExchange = true;
         this.qsoForm.get('sExch').reset(this.sentString);
         this.qsoForm.get('sExch').disable();
         this.qsoEditForm.get('sExch').reset(this.sentString);
         this.qsoEditForm.get('sExch').disable();
      }
      if (this.autoTime) {
         this.qsoForm.get('date').setValue(this.date);
         this.qsoForm.get('time').setValue(new Date(new Date(this.date).getTime() + new Date().getTimezoneOffset() * 60 * 1000));
      }
   }

   sendOffline() {
      this.logService.post<Qso[]>({ eventId: this.eventId, logId: this.logId, qsos: this.offlineQsos }, (res) => {
         if (isResultValid(res)) {
            const qsos: Qso[] = res.body;
            qsos.forEach((q) => {
               const i = this.qsos.findIndex((r) => r.nr === q.nr);
               this.qsos[i]._id = q._id;
               this.qsos[i].status = q.status;
               this.qsos[i].dxcc = q.dxcc;
               this.qsos[i].callsign = q.callsign;
               this.qsos[i].multis = q.multis;
               this.qsos[i].validation = q.validation;
               this.qsos[i].offline = false;
            });
            addIcon(this.qsos, 'edit', new IconClass('fas:faEdit', 'edit', [], translate('Modify QSO')));
            addIcon(this.qsos, 'delete', new IconClass('fas:faTimes', 'delete', [], translate('Delete QSO')));
            convertToBool(this.qsos, ['dupe', 'xQso']);
            this.offlineQsos = [];
            this.isOffline = false;
         }
      });
   }

   onCallsignChange() {
      if (this.dupe) {
         const form = this.editQso ? this.qsoEditForm : this.qsoForm;
         if (form.get('time').value && form.get('time').value !== '') {
            const time = this.toTime(form.get('time').value);
            const h = parseInt(time.split(':')[0]);
            const m = parseInt(time.split(':')[1]);
            let period = 0;
            if (this.periodMins < 60) {
               period = h * 100 + Math.floor(m / this.periodMins) * this.periodMins;
            } else if (this.periodMins === 60) {
               period = h * 100;
            }
            const i = this.qsos.findIndex(
               (q) =>
                  (this.editQso ? q.nr !== form.get('nr').value : true) &&
                  q.call === form.get('call').value &&
                  (this.dupePeriod ? q.period === period : true) &&
                  (this.dupeBand ? q.band === form.get('band').value : true) &&
                  (this.dupeMode ? q.mode === form.get('mode').value : true),
            );
            this.isDupe = i > -1;
         }
      }
   }

   renderTableChange(value: boolean) {
      this.renderTable = value;
   }

   updateEditQso() {
      this.editQso = localStorage.getItem('editQso') === 'true';
   }
}

export interface Qso {
   _id: string;
   nr: number;
   date: string;
   time: string;
   freq?: string;
   band?: string;
   mode: string;
   call: string;
   callsign?: string;
   dateTime?: string;
   sRst?: string | number;
   sNum?: number;
   sExch?: string;
   sWwl?: string;
   rRst?: string | number;
   rNum?: number;
   rExch?: string;
   rWwl?: string;
   edit?: any;
   delete?: any;
   period?: number;
   dupe?: boolean;
   xQso?: boolean;
   status: string;
   dxcc: {
      continent: string;
      cqZone: number;
      ituZone: number;
      key: string;
      prefix: string;
   };
   multis: any[];
   validation: any[];
   offline?: boolean;
}
