import { HttpClient } from '@angular/common/http';
import { AfterContentChecked, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpService, isResultValid } from '@szegedsw/http.service';
import { AppComponent } from '../app.component';
import { api } from '../config/config';

@Component({
   selector: 'app-qsl-member',
   templateUrl: './qsl-member.component.html',
   styleUrls: ['./qsl-member.component.css'],
})
export class QslMemberComponent implements AfterContentChecked {
   searchForm = this.fb.group({
      search: this.fb.control('', Validators.required),
   });

   private callbookService: HttpService;

   isQslMember: boolean;

   isSilentKey: boolean;

   searched: string;

   isLoggedIn: boolean;

   constructor(private http: HttpClient, private fb: FormBuilder) {
      this.callbookService = new HttpService(this.http, api('nest', 'competitor'));
   }

   ngAfterContentChecked() {
      this.isLoggedIn = AppComponent.isRight('ADMIN') || AppComponent.isRight('SYSADMIN') || AppComponent.isRight('EVAL');
   }

   getCallbook(search?: string) {
      this.callbookService.get<{ competitor: boolean; silentKey: boolean }>({ callsign: search }, (res) => {
         if (isResultValid(res)) {
            this.searched = this.searchForm.getRawValue().search;
            this.isQslMember = res.body.competitor;
            this.isSilentKey = res.body.silentKey;
         }
      });
   }

   onSearch() {
      this.getCallbook(this.searchForm.getRawValue().search);
   }

   onAdminDownload() {
      if (this.isLoggedIn) {
         this.callbookService.subRoute('/qsl-admin-list').download({}, () => {
            return;
         });
      }
   }
}
